import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classes from './FreeShippingProgressBar.module.scss'

import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'

const FreeShippingProgressBar = ({
  total,
  shippingThreshold,
  hasFreeShippingDiscount,
  isCheckout,
  isMembership = true,
  hasSuscribeAndSave,
  isExpeditedShipping,
}) => {
  const { updatingLineItemWithExpeditedShipping } = useHeadlessCheckoutContext()
  const [progress, setProgress] = useState(0)
  const [hasFreeShipping, setHasFreeShipping] = useState(false)

  useEffect(() => {
    calculateProgress()
  }, [total, isCheckout])

  const calculateProgress = () => {
    let adjustedTotal = total
    let freeShipping = adjustedTotal >= shippingThreshold || hasFreeShippingDiscount

    setHasFreeShipping(freeShipping)
    setProgress(freeShipping && isCheckout ? 100 : (adjustedTotal / shippingThreshold) * 100)
  }

  if (!isCheckout && (hasFreeShipping || hasFreeShippingDiscount) || hasSuscribeAndSave || isExpeditedShipping) {
    return null
  }

  const freeShippingInfo = (
      hasFreeShipping && isCheckout ? (
        <p  className={`${classes.message} ${!isMembership ? classes.whiteText : ''}`}>
          FREE
        </p>
      ) : (
        <p className={`${classes.message} ${!isMembership ? classes.whiteText : ''}`}>
          ${(shippingThreshold - total).toFixed(2)} away from{' '}
          {'free'.toUpperCase()}!
        </p>
      )
  )

  return (
      <div className={`${classes['shipping-progress-bar']} ${isCheckout ? classes.small : ''}`}>
        {!updatingLineItemWithExpeditedShipping && freeShippingInfo}

        {!hasFreeShipping && isCheckout && (
          <div className={`${classes['progress-bar']} ${isCheckout ? classes.small : ''}`}>
            <div
              className={classes.progress}
              role="progressbar"
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
      </div>
  )
}

FreeShippingProgressBar.propTypes = {
  total: PropTypes.number.isRequired,
  shippingThreshold: PropTypes.number.isRequired,
  hasFreeShippingDiscount: PropTypes.bool,
  isCheckout: PropTypes.bool.isRequired,
}

export default FreeShippingProgressBar
